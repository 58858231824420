import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Metadata } from 'ui/acro/typography'
import { useModal_deprecated, useGetCountry } from 'hooks'

import PrivacyModal from './PrivacyModal'
import TermsModal from './TermsModal'
import CustomTermsModal from './CustomTermsModal'

const ModalLink = styled('span')({
  cursor: 'pointer',
  fontWeight: 'bold',
  textDecoration: 'underline',
  color: '#00E'
})

import { builder } from '@builder.io/sdk'

const Disclaimer = ({
  actionText,
  Wrapper,
  reactivate = false,
  isSignupScreen = true,
  hideSignInDisclimer = false,
  ...rest
}) => {
  console.log('hideSignInDisclimer= ', { hideSignInDisclimer, reactivate })
  const terms = useModal_deprecated()
  const privacy = useModal_deprecated()
  const partnerTerms = useModal_deprecated()
  const country = useGetCountry()

  const [partnerName, setPartnerName] = useState('')
  const [termsContent, setTermsContent] = useState('')
  const [partnerTermsAvailable, setPartnerTermsAvailable] = useState(false)

  useEffect(() => {
    const builderPublicApiKey =
      window.location.hostname === 'www.alomoves.com'
        ? '7ae91e2100c441348821b5902d6e5194'
        : '61e2402350ce4c0cbc02c5526b936de4'

    // Initialize builder.io with the appropriate key
    builder.init(builderPublicApiKey)

    const fetchPartnerTerms = async () => {
      try {
        const response = await builder.get('partner-terms-conditions', {
          userAttributes: { urlPath: window.location.pathname }
        })

        if (response && response.data) {
          const termsData = response.data
          setPartnerName(termsData.partnerName || '')
          setTermsContent(termsData.terms || '')
          setPartnerTermsAvailable(true)
        } else {
          setPartnerTermsAvailable(false)
        }
      } catch (error) {
        console.error('Error fetching partner terms:', error)
        setPartnerTermsAvailable(false)
      }
    }

    fetchPartnerTerms()
  }, [])

  return (
    <Fragment>
      <Wrapper {...rest}>
        By clicking &quot;{actionText}&quot;,
        {(!reactivate && !hideSignInDisclimer) && (
          <>
            {' or using one of the '}
            {isSignupScreen ? 'sign up' : 'sign in'} methods below,
          </>
        )}{' '}
        you agree to
        {partnerTermsAvailable ? ' Alo Moves ' : ' our '}
        <ModalLink onClick={terms.open} id='terms'>
          Terms of Service
        </ModalLink>
        {partnerTermsAvailable ? ', ' : ' and '}
        <ModalLink onClick={privacy.open} id='privacy_policy'>
          Privacy Policy
        </ModalLink>
        {partnerTermsAvailable && (
          <>
            {' and '}
            {partnerName}{' '}
            <ModalLink onClick={partnerTerms.open} id='partner_terms'>
              Terms & Conditions
            </ModalLink>
          </>
        )}
        {'.'}
      </Wrapper>

      {terms.active && <TermsModal useModal={terms} country={country} />}
      {partnerTerms.active && (
        <CustomTermsModal
          useModal={partnerTerms}
          termsContent={termsContent}
          customHeader={partnerName + ' Terms & Conditions'}
        />
      )}
      {privacy.active && <PrivacyModal useModal={privacy} />}
    </Fragment>
  )
}

Disclaimer.defaultProps = {
  Wrapper: Metadata
}

Disclaimer.propTypes = {
  actionText: PropTypes.string.isRequired,
  Wrapper: PropTypes.any,
  country: PropTypes.string.isRequired
}

export default Disclaimer
